import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UtilsJson } from "./../utils/UtilsJson";
function PageContainer() {
  const navigate = useNavigate();
  let UserDetails = localStorage.getItem("details");

  if (!UserDetails) {
    navigate("/");
    return "";
  } else UserDetails = JSON.parse(UserDetails);

  const menu = [
    {
      text: "Dashboard",
      path: "/dashboard",
      icon: "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6",
    },
    {
      text: "Users",
      path: "/list/user",
      icon: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z",
    },   
    {
      text: "Ride",
      path: "/list/ride",
      icon: "M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0",
    },
    {
      text: "Customer",
      path: "/list/customer",
      icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    },
    {
      text: "Booking",
      path: "/list/booking",
      icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01",
    },
    {
      text: "Product",
      path: "/list/product",
      icon: "M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z",
    }
  ];

  return (
    <div className="hidden sm:col-span-2 sm:flex flex-col h-full p-3  bg-primary-900 text-slate-200">
      <div className="space-y-3">
        <div className="flex items-center justify-between">
          <h2 className="w-full text-center">V2 Rental Cars</h2>          
        </div>        
        <div className="flex-1">
          <ul className="pb-4 space-y-1 text-sm">
            {menu.map((m) => (
             
              <li
                key={Math.random().toString().slice(2)}
                className="rounded-sm"
              >
                <NavLink
                  to={`${UtilsJson.routingUrl}${m.path}`}
                  className="flex items-center p-2 space-x-3 rounded-md"
                >
                  <svg
                    className="w-6 h-6 stroke-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      className="text-[#94a3b8]"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={m.icon}
                    />
                  </svg>

                  <span>{m.text} </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bottom-0 flex items-center p-2 mt-2 space-x-4 justify-self-end">
        <div>
          <h1 className="text-xxl font-bold">
            {UserDetails.user_name || ""}
          </h1>
          <span className="flex items-center space-x-1">
            <NavLink
             to={'/'}
              className="text-xxl text-slate-400 cursor-pointer"
            >
              LogOut
            </NavLink>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PageContainer;

export const TableJsonHeaderList = {
  ride: [
    {
      Header: "Ride Id",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    }, {
      Header: "Name",
      accessor: "auto_customer_book_name",
    }, {
      Header: "Mobile Number",
      accessor: "auto_customer_book_number",
    },
    {
      Header: "Start Date",
      accessor: "auto_ride_start",
    }, {
      Header: "End Date",
      accessor: "auto_ride_end",
    }    
  ],
  user: [
    {
      Header: "ID",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    },
    {
      Header: "Name",
      accessor: "auto_login_user",
    }, {
      Header: "User Name",
      accessor: "auto_login_code",
    },
    {
      Header: "Type",
      accessor: "auto_login_type",
    }, {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Active
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],
  customer: [
    {
      Header: "Customer Id",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    },
    {
      Header: "Name",
      accessor: "auto_customer_fname",
    },
    {
      Header: "Phone Number",
      accessor: "auto_customer_phone",
    }
  ],
  booking: [
    {
      Header: "Booking ID",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
      Cell: ({ cell }) => cell.row.values.id ? (
        <h1 className="text-bold">{cell.row.values.id}</h1>
      ) : ""

    }, {
      Header: "Name",
      accessor: "auto_customer_book_name",
    }, {
      Header: "Mobile Number",
      accessor: "auto_customer_book_number",
    }, {
      Header: "Customer Code",
      accessor: "auto_customer_code",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    },
    {
      Header: "Car Code",
      accessor: "auto_product_code",
    },
    {
      Header: "Start Date",
      accessor: "auto_start_date",
    }, {
      Header: "End Date",
      accessor: "auto_end_date",
    }, {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Booked
          </span>
        ) : cell.row.values.status == 2 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Cancel By Customer
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Cancel By Admin
          </span>
        ),
    },
  ],
  product: [
    {
      Header: "Code",
      accessor: "auto_product_code",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "auto_product_name",
    }, {
      Header: "Fueltype",
      accessor: "auto_product_fueltype",
    }, {
      Header: "Seat",
      accessor: "auto_product_seat",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Hidden
          </span>
        ),
    },
  ],

};

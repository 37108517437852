export const FormFieldJson = {
  user: [
    {
      type: "text",
      title: "Name",
      name: "auto_login_user",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "text",
      title: "User Name",
      name: "auto_login_code",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "password",
      title: "Password",
      name: "auto_login_pass",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        },{
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
  customer: [
    {
      type: "text",
      title: "Name",
      name: "auto_customer_fname",
      values: "",
      class: "col-span-3",
      require: false,
    },{
      type: "date",
      title: "Name",
      name: "auto_customer_dob",
      values: "",
      class: "col-span-3",
      require: false,
    },
    {
      type: "text",
      title: "Phone",
      name: "auto_customer_phone",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Email",
      name: "auto_customer_email",
      values: "",
      class: "col-span-3",
      require: false,
    },{
      type: "number",
      title: "Pincode",
      name: "auto_customer_pincode",
      values: "",
      class: "col-span-3",
      require: false,
    },{
      type: "textarea",
      title: "Address",
      name: "auto_customer_address",
      values: "",
      class: "col-span-6",
      require: false,
    },
    {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        },{
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
  product: [
    {
      type: "text",
      title: "Name",
      name: "auto_product_name",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Fuel Type",
      name: "auto_product_fueltype",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "text",
      title: "Gear type",
      name: "auto_product_geartype",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Seating Capacity",
      name: "auto_product_seat",
      values: "",
      class: "col-span-3",
      require: false,
    },{
      type: "file",
      title: "Images",
      name: "auto_product_images",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        },{
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
  booking: [
    {
      type: "text",
      title: "Customer Name",
      name: "auto_customer_book_name",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Mobile Number",
      name: "auto_customer_book_number",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "date",
      title: "Start Date",
      name: "auto_start_date",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "date",
      title: "End Date",
      name: "auto_end_date",
      values: "",
      class: "col-span-3",
      require: true,
    }
  ],ride: [
    {
      type: "text",
      title: "Customer Name",
      name: "auto_customer_book_name",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Mobile Number",
      name: "auto_customer_book_number",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "readonly",
      title: "Start Date",
      name: "auto_ride_start",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "readonly",
      title: "End Date",
      name: "auto_ride_end",
      values: "",
      class: "col-span-3",
      require: false,
    },
    {
      type: "image",
      title: "Start Date",
      name: "auto_start_ride_images",
      values: "",
      class: "col-span-6",
      require: false,
    },
    {
      type: "image",
      title: "End Date",
      name: "auto_ride_end_images",
      values: "",
      class: "col-span-6",
      require: false,
    }
  ],
 };
